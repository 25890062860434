"use client"

import Link from "next/link"
import { usePathname } from "next/navigation"
import { useState, useEffect } from "react"
import { getCalApi } from "@calcom/embed-react"

export function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const pathname = usePathname()

  const isActive = (path: string) => pathname === path

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "30min" })
      cal("ui", { hideEventTypeDetails: false, layout: "month_view" })
    })()
  }, [])

  return (
    <header className="sticky top-0 z-50 bg-[#FBFBFB]/80 backdrop-blur-sm">
      <div className="container mx-auto px-8">
        <div className="flex items-center justify-between h-16">
          {/* Left - Logo with fixed width */}
          <div className="flex-shrink-0 w-[120px]">
            <Link href="/" className="text-xl font-medium text-primary">
              Silvia
            </Link>
          </div>

          {/* Center - Navigation */}
          <nav className="hidden md:flex items-center space-x-8">
            <Link
              href="/"
              className={`${isActive("/") ? "text-primary" : "text-gray-600"} font-medium text-sm hover:text-primary transition-colors`}
            >
              Home
            </Link>
            <Link
              href="/works"
              className={`${isActive("/works") ? "text-primary" : "text-gray-600"} font-medium text-sm hover:text-primary transition-colors`}
            >
              Works
            </Link>
            <Link
              href="https://medium.com/@riantisilvi"
              className={`${isActive("https://medium.com/@riantisilvi") ? "text-primary" : "text-gray-600"} font-medium text-sm hover:text-primary transition-colors`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Medium
            </Link>
          </nav>

          {/* Right - "Book a call" button with fixed width */}
          <div className="hidden md:block w-[120px] text-right">
            <button
              data-cal-namespace="30min"
              data-cal-link="ariantisilvia/30min"
              data-cal-config='{"layout":"month_view"}'
              className="inline-flex items-center justify-center w-full px-4 py-2 border border-gray-200 text-sm font-medium rounded-lg text-gray-900 hover:bg-gray-100 hover:text-gray-900 transition-colors"
            >
              Book a call
            </button>
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden p-2 text-gray-600 hover:text-primary transition-colors"
            aria-label="Toggle menu"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={isMenuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              />
            </svg>
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden border-t border-gray-200">
            <nav className="py-3 space-y-1">
              <Link
                href="/"
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActive("/") ? "text-primary bg-primary/5" : "text-gray-600 hover:text-primary hover:bg-gray-50"
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                Home
              </Link>
              <Link
                href="/works"
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActive("/works") ? "text-primary bg-primary/5" : "text-gray-600 hover:text-primary hover:bg-gray-50"
                }`}
                onClick={() => setIsMenuOpen(false)}
              >
                Works
              </Link>
              <Link
                href="https://medium.com/@riantisilvi"
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  isActive("https://medium.com/@riantisilvi") ? "text-primary bg-primary/5" : "text-gray-600 hover:text-primary hover:bg-gray-50"
                }`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => setIsMenuOpen(false)}
              >
                Medium
              </Link>
              <button
                data-cal-namespace="30min"
                data-cal-link="ariantisilvia/30min"
                data-cal-config='{"layout":"month_view"}'
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-primary hover:bg-gray-50"
                onClick={() => setIsMenuOpen(false)}
              >
                Book a call
              </button>
            </nav>
          </div>
        )}
      </div>
    </header>
  )
}
